import React from "react";

const educationContent = [
  {
    year: "2015",
    degree: "Master of Computer Applications (MCA)",
    institute: "Visvesvaraya Technological University, Karnataka, India",
    details: `Completed MCA with a focus on advanced computer science concepts, software development, and systems management. Developed strong skills in programming, algorithms, and database management.`,
  },
  {
    year: "2012",
    degree: "Bachelor of Science in Computer Science",
    institute: "University of Calicut, Kerala, India",
    details: `Graduated with a strong foundation in computer science principles, including programming, data structures, and software engineering. Engaged in projects that strengthened problem-solving skills and technical knowledge.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-graduation-cap"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
