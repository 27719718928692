import drdoor from "../../assets/img/portfolio/drdoor.webp"
import vkholidays from "../../assets/img/portfolio/vkholidays.webp"
import vodafoneUK from "../../assets/img/portfolio/vodafoneuk.webp"
import fluentCommerceOMS from "../../assets/img/portfolio/fluentcommerceoms.webp"
import irshadck from "../../assets/img/portfolio/irshadck.webp"

const PortfolioData = [
  {
    id: 1,
    type: "Vodafone UK Official Site - Mobile Phones & Home Broadband",
    image: vodafoneUK,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Web Development & Maintenance",
        client: "Vodafone UK through DXC Technology",
        language:
          "React.js, MobX, JavaScript, TypeScript, jQuery, Oracle UCMS, Oracle SiteStudio, Oracle WCP, Node.js, PHP Laravel, MySQL, HTML, SASS, CSS, AWS S3, AWS Cloud, Azure",
        preview: "www.vodafone.co.uk",
        link: "https://www.vodafone.co.uk/",
        description:
          "Developed, modernized, and supported Vodafone UK's customer account self-service portal, handling over 4 million monthly users. Optimized service journeys for mobile and desktop channels, focusing on performance, accessibility, and responsive design.",
      },
    ],
  },
  {
    id: 2,
    type: "Dr.Door - FRP Door Manufacturer",
    image: drdoor,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Dynamic Website Development",
        client: "Dr.Door",
        language:
          "React.js, Redux, JavaScript, TypeScript, Node.js, HTML, SASS, CSS, AWS S3, AWS DynamoDB, AWS Route 53, AWS Lambda, AWS EventBridge, Serverless Framework, Git",
        preview: "www.drdoor.in",
        link: "https://www.drdoor.in/",
        description:
          "Developed a responsive, SEO-friendly website for Dr.Door, an FRP door manufacturer. Focused on accessibility and performance to establish an online presence and increase customer reach.",
      },
    ],
  },
  {
    id: 3,
    type: "VK Holidays - Adventure Holidays & Tours",
    image: vkholidays,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Responsive Web Development",
        client: "VK Holidays",
        language:
          "React.js, Redux, JavaScript, TypeScript, Node.js, HTML, SASS, CSS, AWS S3, AWS DynamoDB, AWS Route 53, AWS Lambda, AWS EventBridge, Serverless Framework, Git",
        preview: "www.vkholidays.com",
        link: "https://stellular-cajeta-c1c5c3.netlify.app/",
        description:
          "Built a dynamic website for VK Holidays to showcase their holiday packages, integrating serverless architecture for scalability and performance.",
      },
    ],
  },
  {
    id: 4,
    type: "Fluent Commerce OMS Customization",
    image: fluentCommerceOMS,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Order Management System Customization",
        client:
          "Lindt, Wiggle, Primark, Emma-sleep, Game Workshop through Ayata Commerce",
        language:
          "React.js, JavaScript, TypeScript, Node.js, HTML, SASS, CSS, Git",
        preview: "No preview available (deployed in private networks)",
        link: "#",
        description:
          "Customized Fluent Commerce's cloud-native distributed Order Management System for omnichannel retail, enabling complex workflows and personalized solutions for leading brands in diverse industries.",
      },
    ],
  },
  {
    id: 5,
    type: "Personal Website - irshadck.com",
    image: irshadck,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Portfolio Website",
        client: "Irshad CK",
        language:
          "React.js, JavaScript, TypeScript, Node.js, HTML, SASS, CSS, Bootstrap, Git",
        preview: "www.irshadck.com",
        link: "https://www.irshadck.com",
        description:
          "Developed my personal portfolio website to showcase my skills, experience, and projects as a Technology Lead & Front-End Developer specializing in React and Next.js.",
      },
    ],
  },
]

export default PortfolioData
