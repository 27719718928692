import React from "react";

const SocialShare = [
  {
    iconName: "fa fa-briefcase",
    link: "https://www.upwork.com/freelancers/~01c5d0ac044fc48298",
  },
  { iconName: "fa fa-linkedin", link: "https://www.linkedin.com/in/irshad-ck-90844429/" },
  {
    iconName: "fa fa-github",
    link: "https://github.com/irshadck",
  },
  { iconName: "fa fa-stack-overflow", link: "https://stackoverflow.com/users/8276943/irshad-ck" },
];

const Social = () => {
  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            <i className={val.iconName}></i>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
