// App.js
import React, { useEffect } from "react"
import ScrollToTop from "./components/ScrollToTop"
import AllRoutes from "./router/AllRoutes"
import AOS from "aos"
import "aos/dist/aos.css"
import AnimatedCursor from "react-animated-cursor"
import { ToastContainer } from "react-toastify"
import ErrorBoundary from "./ErrorBoundary" // Import the error boundary

const App = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 })
  }, [])

  return (
    <>
      <ErrorBoundary>
        <AnimatedCursor
          innerSize={8}
          outerSize={44}
          color="255, 160, 1"
          outerAlpha={0.3}
          innerScale={0.7}
          outerScale={1.2}
        />
        <ScrollToTop />
        <AllRoutes />
        <ToastContainer />
      </ErrorBoundary>
    </>
  )
}

export default App
