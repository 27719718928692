import React from "react";

const experienceContent = [
  {
    year: "Mar 2022 - Present",
    position: "Technology Lead",
    companyName: "Ayata Commerce",
    details: [
      "Led the team to create composable commerce solutions and digital transformations for clients such as American Golf, Breitling, and Chalhoub Group.",
      "Built and managed storefronts using React.js, Next.js, TypeScript, Tailwind CSS, Jotai, and React Context.",
      "Developed BFF layer to integrate multiple systems, including Commercetools, Fluent Commerce, Adyen, Apple Pay, and Amazon Checkout.",
      "Implemented a reverse proxy in AWS as part of a phased digital transformation, ensuring a unified domain and seamless user experience.",
      "Built a custom SDK to share session data and user journey between applications, enabling consistent and synchronized interactions.",
    ],
  },
  {
    year: "Jun 2021 - Mar 2022",
    position: "Senior Software Engineer",
    companyName: "Ayata Commerce",
    details: [
      "Customized Fluent Commerce Order Management System for clients like Lindt, Primark, Emma Sleep, Wiggle, and Game Workshop.",
      "Delivered high-quality, scalable front-end solutions with React.js, Material UI, and TypeScript.",
      "Provided mentoring and training to interns in Fluent Commerce Order Management System integration.",
    ],
  },
  {
    year: "Nov 2015 - May 2021",
    position: "Software Engineer",
    companyName: "DXC Technology",
    details: [
      "Developed Vodafone’s customer account self-service portal, supporting over 4 million users monthly.",
      "Enhanced user journeys, integrated with backend systems, and deployed through a CMS to improve customer experience.",
      "Launched lookup tools that significantly reduced call center traffic by providing customers with service information.",
      "Rebuilt service journeys to improve accessibility, customer satisfaction, and ease of use.",
      "Migrated legacy systems to modern technology stacks, boosting efficiency, maintainability, and performance.",
    ],
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">, {val.companyName}</span>
          </h5>
          <ul className="open-sans-font">
            {val.details.map((item, index) => (
              <li key={index} style={{ marginBottom: "8px", paddingLeft: "16px" }}>
                {item}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
